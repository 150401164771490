@font-face {
    font-family: 'Work Sans';
    src: url('../assets/fonts/Work_Sans/WorkSans-ExtraLight.ttf') format('truetype'),
        url('../assets/fonts/Work_Sans/WorkSans-ExtraLight.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('../assets/fonts/Work_Sans/WorkSans-Thin.ttf') format('truetype'),
        url('../assets/fonts/Work_Sans/WorkSans-Thin.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('../assets/fonts/Work_Sans/WorkSans-Light.ttf') format('truetype'),
        url('../assets/fonts/Work_Sans/WorkSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('../assets/fonts/Work_Sans/WorkSans-Regular.ttf') format('truetype'),
        url('../assets/fonts/Work_Sans/WorkSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('../assets/fonts/Work_Sans/WorkSans-Medium.ttf') format('truetype'),
        url('../assets/fonts/Work_Sans/WorkSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('../assets/fonts/Work_Sans/WorkSans-SemiBold.ttf') format('truetype'),
        url('../assets/fonts/Work_Sans/WorkSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('../assets/fonts/Work_Sans/WorkSans-Bold.ttf') format('truetype'),
        url('../assets/fonts/Work_Sans/WorkSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('../assets/fonts/Work_Sans/WorkSans-ExtraBold.ttf') format('truetype'),
        url('../assets/fonts/Work_Sans/WorkSans-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Work Sans';
    src: url('../assets/fonts/Work_Sans/WorkSans-Black.ttf') format('truetype'),
        url('../assets/fonts/Work_Sans/WorkSans-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
