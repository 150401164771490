@import '_main';

#lum {
    background: linear-gradient(180deg, #07223f 0.01%, #0375ed 100%);
    color: $color-light;
    padding-top: 60px;

    @include media-breakpoint-down(lg) {
        padding-top: 64px;
    }
}

#lum .lum-network-logo {
    max-width: 350px;
    width: 50%;
}

#lum .powered-by {
    position: relative;
    margin-top: 100px;
    margin-bottom: 120px;

    @include media-breakpoint-down(sm) {
        margin-top: 60px;
        margin-bottom: 80px;
    }
}

#lum .powered-by h1 {
    position: relative;
    display: inline-block;
    font-style: normal;
    font-weight: $regular;
    font-size: 10rem;
    line-height: 11rem;
    padding: 0;
    margin: 0;
    text-align: center;
    white-space: nowrap;

    @include media-breakpoint-down(lg) {
        font-size: 7rem;
        line-height: 8rem;
    }

    @include media-breakpoint-down(md) {
        font-size: 5.5rem;
        line-height: 6rem;
    }

    @include media-breakpoint-down(sm) {
        font-size: 5rem;
        line-height: 5.5rem;
        text-align: start;
    }
}

#lum .powered-by h1.part-1 {
    width: 100%;
}

#lum .powered-by h1.part-2 {
    z-index: 2;

    @include media-breakpoint-down(sm) {
        width: 100%;
        z-index: 2;
    }
}

#lum .powered-by img.part-3 {
    position: relative;
    display: inline-block;
    z-index: 2;
    height: 144px;
    margin-left: 40px;
    padding-bottom: 28px;
    width: auto;

    @include media-breakpoint-down(lg) {
        height: 102px;
        margin-left: 28px;
        padding-bottom: 20px;
    }

    @include media-breakpoint-down(md) {
        height: 80px;
        margin-left: 22px;
        padding-bottom: 15px;
    }

    @include media-breakpoint-down(sm) {
        height: 72px;
        margin-top: 10px;
        margin-left: 0;
        margin-bottom: 40px;
        padding-bottom: 0;
        padding-left: 2px;
    }
}

#lum .powered-by img.section-content-illu {
    position: absolute;
    width: 400px;
    height: auto;
    left: 50%;
    top: 0;
    transform: translate(-50%);
    z-index: 0;

    @include media-breakpoint-down(lg) {
        top: 20px;
        width: 280px;
    }

    @include media-breakpoint-down(md) {
        top: 10px;
        width: 220px;
    }

    @include media-breakpoint-down(sm) {
        top: 20%;
        bottom: 40px;
        width: 200px;
        //left: 50%;
        transform: translate(-50%, -50%);
    }
}

#lum .powered-by p {
    font-style: normal;
    font-weight: $regular;
    font-size: 1.125rem;
    line-height: 1.5rem;
    padding: 0;
    margin: 0;
    margin-top: 92px;
    max-width: 90%;

    @include media-breakpoint-down(lg) {
        max-width: none;
        margin-top: 32px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 0.9rem;
        line-height: 1.1rem;
    }
}

#lum .cta {
    margin-top: 44px;

    @include media-breakpoint-down(lg) {
        margin-top: 24px;
    }
}

#lum .input-container {
    max-width: 600px;
    width: 70%;

    @include media-breakpoint-down(md) {
        width: 90%;
    }
}

#lum .input-container input {
    border-radius: 25px;
    height: 50px;
    border: white solid 2px;
    background: transparent;
    padding: 0 18px;
}

#lum .input-container input::placeholder,
#lum .input-container input {
    color: white;
}

#lum .error {
    color: red;
}

#lum .text-link {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

#lum .gotham {
    font-family: 'Gotham Rounded';
}

#lum .lum-ticker {
    height: 15px;
    object-fit: contain;
}

#lum .address-container .address {
    @include media-breakpoint-down(sm) {
        max-width: 60%;
    }
}
