.footer-logo {
    width: 27px;
    height: 27px;
    opacity: 0.7;
    transition: all 0.15s ease-in-out;

    &:hover {
        opacity: 1;
    }
}
