@font-face {
    font-family: 'Gotham Rounded';
    src: url('../assets/fonts/GothamRounded/GothamRounded-Light.otf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: url('../assets/fonts/GothamRounded/GothamRounded-Book.otf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: url('../assets/fonts/GothamRounded/GothamRounded-Book.otf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: url('../assets/fonts/GothamRounded/GothamRounded-Medium.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: url('../assets/fonts/GothamRounded/GothamRounded-Medium.otf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: url('../assets/fonts/GothamRounded/GothamRounded-Medium.otf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: url('../assets/fonts/GothamRounded/GothamRounded-Bold.otf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: url('../assets/fonts/GothamRounded/GothamRounded-Bold.otf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: url('../assets/fonts/GothamRounded/GothamRounded-Bold.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
